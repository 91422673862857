<template>
    <b-button-group>
        <b-button variant="light iq-bg-light"
                  size="sm"
                  v-if="!actions.isVerified"
                  @click="verify(data.item)">
            Verify
        </b-button>
        <b-button variant="light iq-bg-success"
                  size="sm"
                  v-b-tooltip.hover title="Verified"
                  v-else>
            <i class="fas fa-check-circle"></i>
        </b-button>
        <b-button variant="light iq-bg-light"
                  size="sm"
                  v-if="!actions.reviewPosted"
                  @click="review(data.item)">
            Review
        </b-button>
        <b-button variant="light iq-bg-light"
                  size="sm"
                  @click="view(data.item)">
            View
        </b-button>
    </b-button-group>
</template>

<script>
export default {
    props: ['data', 'actions'],
    name: 'ActionButton',
    methods: {
        verify (item) {
            this.$emit('verifyClicked', item)
        },
        review (item) {
            this.$emit('reviewClicked', item)
        },
        view (item) {
            this.$emit('viewClicked', item)
        }
    }
}
</script>

<style scoped>

</style>
