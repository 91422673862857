<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                                        <b-form @submit.prevent="handleSubmit(createData)" @reset.prevent="reset">
                                            <b-row>
                                                <b-col lg="12" sm="12">
                                                    <ValidationProvider name="Task Note" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="2"
                                                            label-for="note"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label> {{ $t('irrigation_task.note') }} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-textarea
                                                                id="note"
                                                                v-model="irrigationTaskReview.note"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                placeholder="Enter note"
                                                                rows="3"
                                                                max-rows="6"
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="12" sm="12">
                                                    <ValidationProvider name="Task Note (BN)" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="2"
                                                            label-for="note_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label> {{ $t('irrigation_task.note_bn') }} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-textarea
                                                                id="note_bn"
                                                                v-model="irrigationTaskReview.note_bn"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                placeholder="Enter note"
                                                                rows="3"
                                                                max-rows="6"
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { taskReviewStore, taskReviewUpdate } from '../../api/routes'
import { Common, Repository } from '@/mixins/helper-functions'

export default {
    props: ['id', 'taskID'],
    mixins: [Common],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            irrigationTaskReview: {
                task_id: this.taskID,
                note: '',
                note_bn: ''
            }
        }
    },
    methods: {
        async createData () {
            this.loading = true
            const vm = this
            await Repository.create({
                data: this.irrigationTaskReview,
                baseURL: irriSchemeServiceBaseUrl,
                storeAPI: taskReviewStore,
                updateAPI: taskReviewUpdate,
                itemId: this.id,
                modalId: 'review-modal',
                onSuccess: function () {
                    vm.$emit('submitted')
                }
            })
            this.loading = false
        }
    }
}
</script>
