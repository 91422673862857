<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('irrigation_task.task_tracker') }} </h4>
            </template>
            <template v-slot:body>
                <form-search :data="searchAttributes" @returned-data="searchData"/>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('irrigation_task.task_tracker') }} {{ $t('globalTrans.list') }} </h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered hover :items="listData"
                                             :fields="columns"
                                             :aria-hidden="(loading || listReload) ? true : null"
                                    >
                                        <template #thead-top="">
                                            <b-tr>
                                                <b-th colspan="6" class="text-center font-size-18">{{ $t('irrigation_task.assigned_task') }}</b-th>
                                                <b-th colspan="4" class="text-center font-size-18">{{ $t('irrigation_task.task_report') }}</b-th>
                                                <b-th></b-th>
                                            </b-tr>
                                        </template>
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template #cell(task_assignment_attachment)="data">
                                            <a v-if="data.value" :href="data.value" target="_blank" download> {{ $t('globalTrans.link') }} </a>
                                            <span v-else>N/A</span>
                                        </template>
                                        <template #cell(task_report_attachment)="data">
                                            <a v-if="data.value" :href="data.value" target="_blank" download> {{ $t('globalTrans.link') }} </a>
                                            <span v-else>N/A</span>
                                        </template>
                                        <template #cell(schedule)="data">
                                            <span v-if="data.item.task_from">{{ $t('globalTrans.from') }}: {{ data.item.task_from | dateFormat }}</span><br>
                                            <span v-if="data.item.task_to">{{ $t('irrigation_task.to') }}: {{ data.item.task_to | dateFormat }}</span>
                                            <span class="text-muted" v-else>
                                                N/A
                                            </span>
                                        </template>
                                        <template #cell(task_report_note_short)="data">
                                            <div v-html="data.value"></div>
                                        </template>
                                        <template #cell(task_report_note_short_bn)="data">
                                            <div v-html="data.value"></div>
                                        </template>
                                        <template #cell(task_reports_task_date)="data">
                                            <div>{{ data.value ? dateFormat(data.value) : 'N/A' }}</div>
                                        </template>
                                        <template #cell(complete_type_id)="data">
                                            <div>{{ getCompleteStatus(data.item.complete_type_id) }}</div>
                                        </template>
                                        <template v-slot:cell(updated_at)="data">
                                            <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFormat}}</span>
                                        </template>
                                        <template #cell(action)="data">
                                            <action-button
                                                :data="data"
                                                :actions="{
                                                    isVerified: data.item.is_verified,
                                                    reviewPosted:  data.item.task_review_task_id
                                                }"
                                                @verifyClicked="verify"
                                                @reviewClicked="review"
                                                @viewClicked="show(data.item)"
                                            />
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="dataChange"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <b-modal id="review-modal" size="lg" :title="formTitle" :hide-footer=true>
            <FormV :id="testId" :key="testId" :taskID="taskID" @submitted="dataChange"/>
        </b-modal>
        <b-modal id="item-show" size="lg" :title="$t('irrigation_task.task_tracker')" :hide-footer=true>
            <item-view :prop-data="itemsToView" :loading="load" />
        </b-modal>
        <b-modal id="view-item" title="Details" size="lg" :hide-footer=true>
            <div v-html="viewData"></div>
        </b-modal>
    </b-container>
</template>
<script>
import FormV from './Form'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { taskTrackerList, taskAssignVerify } from '../../api/routes'
import { List, Common, helpers } from '@/mixins/helper-functions'
import ActionButton from './ActionButton'
import FormSearch from '@/components/structure/form/search/FormSearch'
import ItemView from '@/components/structure/itemView'
import { dateFormat } from '@/Utils/fliter'

export default {
    mixins: [List, Common],
    components: {
        FormV, ActionButton, FormSearch, ItemView
    },
    data () {
        return {
            load: false,
            taskID: '',
            viewData: '',
            search: {
                task_id: '',
                task_name: '',
                assign_user_id: ''
            },
            viewNote: false,
            testId: 0,
            rows: [],
            defaultNull: '',
            currentItem: null,
            taskAssignmentAttachmentPath: irriSchemeServiceBaseUrl + 'public/uploads/task-assign-tasks/original/',
            taskReportAttachmentPath: irriSchemeServiceBaseUrl + 'public/uploads/task-reports/original/'
        }
    },
    computed: {
        isBn () {
            return this.$i18n.locale === 'bn'
        },
        itemsToView () {
            return {
                title: '',
                data: [
                    {
                        title: this.$t('irrigation_task.task_tracker_details'),
                        type: 'table',
                        data: [
                            {
                                title1: this.$t('irrigation_task.task_name'),
                                value1: this.isBn ? this.taskTrackerInfo.task_name_bn : this.taskTrackerInfo.task_name,
                                title2: this.$t('irrigation_task.responsible'),
                                value2: this.taskTrackerInfo.assign_username
                            },
                            {
                                title1: this.$t('irrigation_task.task_report_date'),
                                value1: this.taskTrackerInfo.task_reports_task_date ? dateFormat(this.taskTrackerInfo.task_reports_task_date) : 'N/A',
                                colSpan: {
                                    value1: 3
                                }
                            },
                            {
                                title1: this.$t('irrigation_task.task_assignment_attachment'),
                                value1: this.getAttachmentLink(this.taskAssignmentAttachmentPath, this.taskTrackerInfo.task_assignment_attachment),
                                title2: this.$t('irrigation_task.task_report_attachment'),
                                value2: this.getAttachmentLink(this.taskReportAttachmentPath, this.taskTrackerInfo.task_reports_attachment),
                                config: {
                                    value1: {
                                        type: 'html'
                                    },
                                    value2: {
                                        type: 'html'
                                    }
                                }
                            },
                            {
                                title1: this.$t('irrigation_task.task_assignment_note'),
                                value1: this.taskTrackerInfo.task_assignment_note ? (this.isBn ? this.taskTrackerInfo.task_assignment_note_bn : this.taskTrackerInfo.task_assignment_note) : 'N/A',
                                colSpan: {
                                    value1: 3
                                }
                            },
                            {
                                title1: this.$t('irrigation_task.task_report_note'),
                                value1: this.isBn ? this.taskTrackerInfo.task_reports_note_bn : this.taskTrackerInfo.task_reports_note,
                                colSpan: {
                                    value1: 3
                                }
                            },
                            {
                                title1: this.$t('irrigation_task.task_review_note'),
                                value1: this.taskTrackerInfo.task_review_note ? (this.isBn ? this.taskTrackerInfo.task_review_note_bn : this.taskTrackerInfo.task_review_note) : 'N/A',
                                colSpan: {
                                    value1: 3
                                }
                            },
                            {
                                title1: this.$t('irrigation_task.verified'),
                                value1: this.taskTrackerInfo.is_verified ? this.$t('globalTrans.yes') : this.$t('globalTrans.no'),
                                title2: this.$t('globalTrans.status'),
                                value2: this.getCompleteStatus(this.taskTrackerInfo.complete_type_id)
                            }
                        ]
                    }
                ]
            }
        },
        taskTypeList () {
            return this.$store.state.commonObj.irrigationTaskTypeList
        },
        formTitle () {
            return (this.testId === 0) ? this.$t('irrigation_task.task_review') + ' ' + this.$t('globalTrans.entry')
                : this.$t('irrigation_task.task_review') + ' ' + this.$t('globalTrans.modify')
        },
        listData () {
            return this.$store.state.IrriTask.taskTrackerList
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('irrigation_task.task_id'), class: 'text-center' },
                { label: this.$t('irrigation_task.task_name'), class: 'text-center' },
                { label: this.$t('irrigation_task.responsible'), class: 'text-center' },
                { label: this.$t('globalTrans.attachment'), class: 'text-center' },
                { label: this.$t('globalTrans.schedule'), class: 'text-center' },

                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.attachment'), class: 'text-center' },
                { label: this.$t('irrigation_task.note'), class: 'text-center' },
                { label: this.$t('globalTrans.calendar_date'), class: 'text-center' },
                { label: this.$t('pump_install.created_date'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            var keys = [
                { key: 'index' },
                { key: 'task_id' },
                { key: 'task_name' },
                { key: 'assign_username' },
                { key: 'task_assignment_attachment' },
                { key: 'schedule' },
                { key: 'complete_type_id' },
                { key: 'task_report_attachment' },
                { key: 'task_report_note' },
                { key: 'task_reports_task_date' },
                { key: 'updated_at' },
                { key: 'action' }
            ]

            if (this.$i18n.locale === 'bn') {
                keys[2] = { key: 'task_name_bn' }
                keys[6] = { key: 'complete_type_bn' }
                keys[9] = { key: 'task_report_note_bn' }
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        searchAttributes () {
            return {
                buttonText: this.$t('globalTrans.search'),
                data: [
                    {
                        name: 'task_id',
                        type: 'Input',
                        label: 'irrigation_task.task_id',
                        labelCol: 3,
                        lg: 4,
                        md: 6,
                        sm: 12
                    },
                    {
                        name: 'task_name',
                        type: 'Input',
                        label: 'irrigation_task.task_name',
                        labelCol: 3,
                        lg: 4,
                        md: 6,
                        sm: 12
                    },
                    {
                        name: 'assign_user_id',
                        type: 'Input',
                        label: 'irrigation_task.responsible',
                        labelCol: 3,
                        lg: 4,
                        md: 6,
                        sm: 12
                    }
                ]
            }
        },
        taskTrackerInfo () {
            return this.$store.state.IrriTask.taskInfo
        }
    },
    created () {
        this.$store.dispatch('IrriTask/addTaskInfo', {})
        this.loadData()
    },
    methods: {
        dateFormat (date) {
          return dateFormat(date)
        },
        getAttachmentLink (path, name) {
            return `<a href="${path + name}" target="_blank" download>${this.$t('globalTrans.link')}</a>`
        },
        status (condition) {
            return condition ? this.$t('globalTrans.active') : this.$t('globalTrans.inactive')
        },
        getCompleteStatus (status) {
            let returnStatus = this.isBn ? 'বিচারাধীন' : 'Pending'
            if (status) {
                const statusList = Object.freeze(this.$store.state.IrriTask.taskReportStatusList)
                const foundStatus = statusList.find(el => el.value === status)
                if (foundStatus) {
                    returnStatus = this.isBn ? foundStatus.text_bn : foundStatus.text_en
                }
            }
            return returnStatus
        },
        async show (item) {
            this.load = true
            this.$bvModal.show('item-show')
            const response = await RestApi.getData(irriSchemeServiceBaseUrl, `${taskTrackerList}/${item.task_id}`)
            if (response.success) {
                const data = response.data ? response.data : null
                if (data) {
                    await this.$store.dispatch('IrriTask/addTaskInfo', data)
                }
            }
            this.load = false
        },
        async sendVerifyRequest (item) {
            this.load = true
            await RestApi.deleteData(irriSchemeServiceBaseUrl, `${taskAssignVerify}/${item.task_id}`)
                .then(response => {
                    helpers.successErrorToaster(response, () => {
                        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
                    })
                })
            this.load = false
        },
        verify (item) {
            this.$swal({
                title: this.$t('globalTrans.surePrompt'),
                showCancelButton: true,
                confirmButtonText: this.$t('globalTrans.yes'),
                cancelButtonText: this.$t('globalTrans.no'),
                focusConfirm: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.sendVerifyRequest(item)
                }
            })
        },
        review (item) {
            this.taskID = item.task_id
            this.$bvModal.show('review-modal')
        },
        resetId () {
            this.testId = 0
        },
        dataChange () {
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length,
                editable: false
            }
        },
        async searchData (payload) {
            this.search = payload
            await this.loadData()
        },
        loadData () {
            try {
                const status = this.$route.query.task_type_id
                let searchData = ''
                if (status !== undefined) {
                    searchData = Object.assign({}, this.search, { task_type_id: status })
                } else {
                    searchData = this.search
                }
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const params = Object.assign({}, searchData, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
                RestApi.getData(irriSchemeServiceBaseUrl, taskTrackerList, params).then(async (response) => {
                    if (response.success) {
                        const data = await this.dataList(response)
                        if (typeof data.data !== 'undefined') {
                            const listData = data.data.filter(item => typeof item !== 'undefined')
                            await this.$store.dispatch('IrriTask/irriTaskTrackerList', listData)
                            this.paginationData(data.pagination)
                        }
                    }
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                })
            } catch (e) {
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }
        },
        async dataList (response) {
            // Formatted object to fit in helper-function
            const completeTypes = Object.freeze(this.$store.state.IrriTask.taskReportStatusList)
            let listData = {}
            listData = await response.data.data.map(item => {
                item.task_id = this.$n(item.task_id)
                item.assign_user_id = this.$n(item.assign_user_id)
                item.task_assignment_status = !item.task_assignment_status ? 'Active' : 'Inactive'
                item.task_assignment_status_bn = !item.task_assignment_status ? 'সক্রিয়' : 'নিষ্ক্রিয়'
                item.task_assignment_attachment = item.task_assignment_attachment ? irriSchemeServiceBaseUrl +
                    'uploads/task-assign-tasks/original/' +
                    item.task_assignment_attachment : null
                item.task_report_attachment = item.task_reports_attachment ? irriSchemeServiceBaseUrl +
                    'uploads/task-reports/original/' +
                    item.task_reports_attachment : null
                item.task_report_note_full = item.task_reports_note
                item.task_reports_note_bn_full = item.task_reports_note_bn

                item.task_report_note = helpers.stringLimit(item.task_reports_note, 10)
                item.task_reports_note_bn = helpers.stringLimit(item.task_reports_note_bn, 10)
                const completeTypesObject = completeTypes.find(el => el.value === item.complete_type_id)

                item.complete_type_en = completeTypesObject !== undefined ? completeTypesObject.text_en : ''
                item.complete_type_bn = completeTypesObject !== undefined ? completeTypesObject.text_bn : ''
                return item
            })
            delete response.data.data
            return {
                data: listData,
                pagination: response.data
            }
        }
    }
}
</script>
